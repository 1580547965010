@-webkit-keyframes animate-svg-stroke-1 {
    0% {
        stroke-dashoffset: 1065.87841796875px;
        stroke-dasharray: 1065.87841796875px;
    }

    100% {
        stroke-dashoffset: 2131.7568359375px;
        stroke-dasharray: 1065.87841796875px;
    }
}

@keyframes animate-svg-stroke-1 {
    0% {
        stroke-dashoffset: 1065.87841796875px;
        stroke-dasharray: 1065.87841796875px;
    }

    100% {
        stroke-dashoffset: 2131.7568359375px;
        stroke-dasharray: 1065.87841796875px;
    }
}

@-webkit-keyframes animate-svg-stroke-2 {
    0% {
        stroke-dashoffset: 1770.920166015625px;
        stroke-dasharray: 1770.920166015625px;
    }

    100% {
        stroke-dashoffset: 3541.84033203125px;
        stroke-dasharray: 1770.920166015625px;
    }
}

@keyframes animate-svg-stroke-2 {
    0% {
        stroke-dashoffset: 1770.920166015625px;
        stroke-dasharray: 1770.920166015625px;
    }

    100% {
        stroke-dashoffset: 3541.84033203125px;
        stroke-dasharray: 1770.920166015625px;
    }
}

@-webkit-keyframes animate-svg-fill-2 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@keyframes animate-svg-fill-2 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}


/* link */


@-webkit-keyframes animate-svg-stroke-1-link {
    0% {
        stroke-dashoffset: 690.8343505859375px;
        stroke-dasharray: 690.8343505859375px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 690.8343505859375px;
    }
}

@keyframes animate-svg-stroke-1-link {
    0% {
        stroke-dashoffset: 690.8343505859375px;
        stroke-dasharray: 690.8343505859375px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 690.8343505859375px;
    }
}

@-webkit-keyframes animate-svg-stroke-2-link {
    0% {
        stroke-dashoffset: 690.8341064453125px;
        stroke-dasharray: 690.8341064453125px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 690.8341064453125px;
    }
}

@keyframes animate-svg-stroke-2-link {
    0% {
        stroke-dashoffset: 690.8341064453125px;
        stroke-dasharray: 690.8341064453125px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 690.8341064453125px;
    }
}

@-webkit-keyframes animate-svg-stroke-3-link {
    0% {
        stroke-dashoffset: 612.841552734375px;
        stroke-dasharray: 612.841552734375px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 612.841552734375px;
    }
}

@keyframes animate-svg-stroke-3-link {
    0% {
        stroke-dashoffset: 612.841552734375px;
        stroke-dasharray: 612.841552734375px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 612.841552734375px;
    }
}

@-webkit-keyframes animate-svg-fill-3-link {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@keyframes animate-svg-fill-3-link {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@-webkit-keyframes animate-svg-stroke-4-link {
    0% {
        stroke-dashoffset: 612.8428344726562px;
        stroke-dasharray: 612.8428344726562px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 612.8428344726562px;
    }
}

@keyframes animate-svg-stroke-4-link {
    0% {
        stroke-dashoffset: 612.8428344726562px;
        stroke-dasharray: 612.8428344726562px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 612.8428344726562px;
    }
}

@-webkit-keyframes animate-svg-fill-4-link {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@keyframes animate-svg-fill-4-link {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}


/* bot */


@-webkit-keyframes animate-svg-stroke-1-bot {
    0% {
        stroke-dashoffset: 1132.4842529296875px;
        stroke-dasharray: 1132.4842529296875px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 1132.4842529296875px;
    }
}

@keyframes animate-svg-stroke-1-bot {
    0% {
        stroke-dashoffset: 1132.4842529296875px;
        stroke-dasharray: 1132.4842529296875px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 1132.4842529296875px;
    }
}

@-webkit-keyframes animate-svg-stroke-2-bot {
    0% {
        stroke-dashoffset: 745.8360595703125px;
        stroke-dasharray: 745.8360595703125px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 745.8360595703125px;
    }
}

@keyframes animate-svg-stroke-2-bot {
    0% {
        stroke-dashoffset: 745.8360595703125px;
        stroke-dasharray: 745.8360595703125px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 745.8360595703125px;
    }
}

@-webkit-keyframes animate-svg-stroke-3-bot {
    0% {
        stroke-dashoffset: 165.36281798666926px;
        stroke-dasharray: 165.36281798666926px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 165.36281798666926px;
    }
}

@keyframes animate-svg-stroke-3-bot {
    0% {
        stroke-dashoffset: 165.36281798666926px;
        stroke-dasharray: 165.36281798666926px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 165.36281798666926px;
    }
}

@-webkit-keyframes animate-svg-fill-3-bot {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@keyframes animate-svg-fill-3-bot {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@-webkit-keyframes animate-svg-stroke-4-bot {
    0% {
        stroke-dashoffset: 165.36281798666926px;
        stroke-dasharray: 165.36281798666926px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 165.36281798666926px;
    }
}

@keyframes animate-svg-stroke-4-bot {
    0% {
        stroke-dashoffset: 165.36281798666926px;
        stroke-dasharray: 165.36281798666926px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 165.36281798666926px;
    }
}

@-webkit-keyframes animate-svg-fill-4-bot {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@keyframes animate-svg-fill-4-bot {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@-webkit-keyframes animate-svg-stroke-5-bot {
    0% {
        stroke-dashoffset: 80.11165618896484px;
        stroke-dasharray: 80.11165618896484px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 80.11165618896484px;
    }
}

@keyframes animate-svg-stroke-5-bot {
    0% {
        stroke-dashoffset: 80.11165618896484px;
        stroke-dasharray: 80.11165618896484px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 80.11165618896484px;
    }
}



/* lms */



@-webkit-keyframes animate-svg-stroke-1-lms {
    0% {
        stroke-dashoffset: 1506.611328125px;
        stroke-dasharray: 1506.611328125px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 1506.611328125px;
    }
}

@keyframes animate-svg-stroke-1-lms {
    0% {
        stroke-dashoffset: 1506.611328125px;
        stroke-dasharray: 1506.611328125px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 1506.611328125px;
    }
}

@-webkit-keyframes animate-svg-stroke-2-lms {
    0% {
        stroke-dashoffset: 238.18429565429688px;
        stroke-dasharray: 238.18429565429688px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 238.18429565429688px;
    }
}

@keyframes animate-svg-stroke-2-lms {
    0% {
        stroke-dashoffset: 238.18429565429688px;
        stroke-dasharray: 238.18429565429688px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 238.18429565429688px;
    }
}

@-webkit-keyframes animate-svg-stroke-3-lms {
    0% {
        stroke-dashoffset: 82.8422622680664px;
        stroke-dasharray: 82.8422622680664px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 82.8422622680664px;
    }
}

@keyframes animate-svg-stroke-3-lms {
    0% {
        stroke-dashoffset: 82.8422622680664px;
        stroke-dasharray: 82.8422622680664px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 82.8422622680664px;
    }
}

@-webkit-keyframes animate-svg-fill-3-lms {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@keyframes animate-svg-fill-3-lms {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@-webkit-keyframes animate-svg-stroke-4-lms {
    0% {
        stroke-dashoffset: 162.22122533307945px;
        stroke-dasharray: 162.22122533307945px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 162.22122533307945px;
    }
}

@keyframes animate-svg-stroke-4-lms {
    0% {
        stroke-dashoffset: 162.22122533307945px;
        stroke-dasharray: 162.22122533307945px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 162.22122533307945px;
    }
}

@-webkit-keyframes animate-svg-stroke-5-lms {
    0% {
        stroke-dashoffset: 43.845558166503906px;
        stroke-dasharray: 43.845558166503906px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 43.845558166503906px;
    }
}

@keyframes animate-svg-stroke-5-lms {
    0% {
        stroke-dashoffset: 43.845558166503906px;
        stroke-dasharray: 43.845558166503906px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 43.845558166503906px;
    }
}

@-webkit-keyframes animate-svg-stroke-6-lms {
    0% {
        stroke-dashoffset: 83px;
        stroke-dasharray: 83px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 83px;
    }
}

@keyframes animate-svg-stroke-6-lms {
    0% {
        stroke-dashoffset: 83px;
        stroke-dasharray: 83px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 83px;
    }
}

@-webkit-keyframes animate-svg-stroke-7-lms {
    0% {
        stroke-dashoffset: 83px;
        stroke-dasharray: 83px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 83px;
    }
}

@keyframes animate-svg-stroke-7-lms {
    0% {
        stroke-dashoffset: 83px;
        stroke-dasharray: 83px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 83px;
    }
}

@-webkit-keyframes animate-svg-stroke-8-lms {
    0% {
        stroke-dashoffset: 83px;
        stroke-dasharray: 83px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 83px;
    }
}

@keyframes animate-svg-stroke-8-lms {
    0% {
        stroke-dashoffset: 83px;
        stroke-dasharray: 83px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 83px;
    }
}

@-webkit-keyframes animate-svg-stroke-9-lms {
    0% {
        stroke-dashoffset: 83px;
        stroke-dasharray: 83px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 83px;
    }
}

@keyframes animate-svg-stroke-9-lms {
    0% {
        stroke-dashoffset: 83px;
        stroke-dasharray: 83px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 83px;
    }
}

@-webkit-keyframes animate-svg-stroke-10-lms {
    0% {
        stroke-dashoffset: 83px;
        stroke-dasharray: 83px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 83px;
    }
}

@keyframes animate-svg-stroke-10-lms {
    0% {
        stroke-dashoffset: 83px;
        stroke-dasharray: 83px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 83px;
    }
}

@-webkit-keyframes animate-svg-stroke-11-lms {
    0% {
        stroke-dashoffset: 248.66966247558594px;
        stroke-dasharray: 248.66966247558594px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 248.66966247558594px;
    }
}

@keyframes animate-svg-stroke-11-lms {
    0% {
        stroke-dashoffset: 248.66966247558594px;
        stroke-dasharray: 248.66966247558594px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 248.66966247558594px;
    }
}

@-webkit-keyframes animate-svg-stroke-12-lms {
    0% {
        stroke-dashoffset: 178.03372192382812px;
        stroke-dasharray: 178.03372192382812px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 178.03372192382812px;
    }
}

@keyframes animate-svg-stroke-12-lms {
    0% {
        stroke-dashoffset: 178.03372192382812px;
        stroke-dasharray: 178.03372192382812px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 178.03372192382812px;
    }
}

@-webkit-keyframes animate-svg-fill-12-lms {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@keyframes animate-svg-fill-12-lms {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@-webkit-keyframes animate-svg-stroke-13-lms {
    0% {
        stroke-dashoffset: 178.0337677001953px;
        stroke-dasharray: 178.0337677001953px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 178.0337677001953px;
    }
}

@keyframes animate-svg-stroke-13-lms {
    0% {
        stroke-dashoffset: 178.0337677001953px;
        stroke-dasharray: 178.0337677001953px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 178.0337677001953px;
    }
}

@-webkit-keyframes animate-svg-fill-13-lms {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@keyframes animate-svg-fill-13-lms {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@-webkit-keyframes animate-svg-stroke-14-lms {
    0% {
        stroke-dashoffset: 178.03370666503906px;
        stroke-dasharray: 178.03370666503906px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 178.03370666503906px;
    }
}

@keyframes animate-svg-stroke-14-lms {
    0% {
        stroke-dashoffset: 178.03370666503906px;
        stroke-dasharray: 178.03370666503906px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 178.03370666503906px;
    }
}

@-webkit-keyframes animate-svg-fill-14-lms {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@keyframes animate-svg-fill-14-lms {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@-webkit-keyframes animate-svg-stroke-15-lms {
    0% {
        stroke-dashoffset: 546.3588256835938px;
        stroke-dasharray: 546.3588256835938px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 546.3588256835938px;
    }
}

@keyframes animate-svg-stroke-15-lms {
    0% {
        stroke-dashoffset: 546.3588256835938px;
        stroke-dasharray: 546.3588256835938px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 546.3588256835938px;
    }
}

@-webkit-keyframes animate-svg-stroke-16-lms {
    0% {
        stroke-dashoffset: 71.11503837897544px;
        stroke-dasharray: 71.11503837897544px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 71.11503837897544px;
    }
}

@keyframes animate-svg-stroke-16-lms {
    0% {
        stroke-dashoffset: 71.11503837897544px;
        stroke-dasharray: 71.11503837897544px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 71.11503837897544px;
    }
}

@-webkit-keyframes animate-svg-fill-16-lms {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@keyframes animate-svg-fill-16-lms {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@-webkit-keyframes animate-svg-stroke-17-lms {
    0% {
        stroke-dashoffset: 71.11503837897544px;
        stroke-dasharray: 71.11503837897544px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 71.11503837897544px;
    }
}

@keyframes animate-svg-stroke-17-lms {
    0% {
        stroke-dashoffset: 71.11503837897544px;
        stroke-dasharray: 71.11503837897544px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 71.11503837897544px;
    }
}

@-webkit-keyframes animate-svg-fill-17-lms {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@keyframes animate-svg-fill-17-lms {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@-webkit-keyframes animate-svg-stroke-18-lms {
    0% {
        stroke-dashoffset: 71.11503837897544px;
        stroke-dasharray: 71.11503837897544px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 71.11503837897544px;
    }
}

@keyframes animate-svg-stroke-18-lms {
    0% {
        stroke-dashoffset: 71.11503837897544px;
        stroke-dasharray: 71.11503837897544px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 71.11503837897544px;
    }
}

@-webkit-keyframes animate-svg-fill-18-lms {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@keyframes animate-svg-fill-18-lms {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}


/* fid */


@-webkit-keyframes animate-svg-stroke-1-fid {
    0% {
        stroke-dashoffset: 613.9232788085938px;
        stroke-dasharray: 613.9232788085938px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 613.9232788085938px;
    }
}

@keyframes animate-svg-stroke-1-fid {
    0% {
        stroke-dashoffset: 613.9232788085938px;
        stroke-dasharray: 613.9232788085938px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 613.9232788085938px;
    }
}

@-webkit-keyframes animate-svg-stroke-2-fid {
    0% {
        stroke-dashoffset: 613.9234619140625px;
        stroke-dasharray: 613.9234619140625px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 613.9234619140625px;
    }
}

@keyframes animate-svg-stroke-2-fid {
    0% {
        stroke-dashoffset: 613.9234619140625px;
        stroke-dasharray: 613.9234619140625px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 613.9234619140625px;
    }
}

@-webkit-keyframes animate-svg-stroke-3-fid {
    0% {
        stroke-dashoffset: 70.5px;
        stroke-dasharray: 70.5px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 70.5px;
    }
}

@keyframes animate-svg-stroke-3-fid {
    0% {
        stroke-dashoffset: 70.5px;
        stroke-dasharray: 70.5px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 70.5px;
    }
}

@-webkit-keyframes animate-svg-stroke-4-fid {
    0% {
        stroke-dashoffset: 230.12850952148438px;
        stroke-dasharray: 230.12850952148438px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 230.12850952148438px;
    }
}

@keyframes animate-svg-stroke-4-fid {
    0% {
        stroke-dashoffset: 230.12850952148438px;
        stroke-dasharray: 230.12850952148438px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 230.12850952148438px;
    }
}

@-webkit-keyframes animate-svg-stroke-5-fid {
    0% {
        stroke-dashoffset: 157.0526885986328px;
        stroke-dasharray: 157.0526885986328px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 157.0526885986328px;
    }
}

@keyframes animate-svg-stroke-5-fid {
    0% {
        stroke-dashoffset: 157.0526885986328px;
        stroke-dasharray: 157.0526885986328px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 157.0526885986328px;
    }
}

@-webkit-keyframes animate-svg-fill-5-fid {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@keyframes animate-svg-fill-5-fid {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(89, 178, 86);
    }
}

@-webkit-keyframes animate-svg-stroke-6-fid {
    0% {
        stroke-dashoffset: 298.4991149902344px;
        stroke-dasharray: 298.4991149902344px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 298.4991149902344px;
    }
}

@keyframes animate-svg-stroke-6-fid {
    0% {
        stroke-dashoffset: 298.4991149902344px;
        stroke-dasharray: 298.4991149902344px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 298.4991149902344px;
    }
}

@-webkit-keyframes animate-svg-stroke-7-fid {
    0% {
        stroke-dashoffset: 298.4991149902344px;
        stroke-dasharray: 298.4991149902344px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 298.4991149902344px;
    }
}

@keyframes animate-svg-stroke-7-fid {
    0% {
        stroke-dashoffset: 298.4991149902344px;
        stroke-dasharray: 298.4991149902344px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 298.4991149902344px;
    }
}

@-webkit-keyframes animate-svg-stroke-8-fid {
    0% {
        stroke-dashoffset: 375.84952577718536px;
        stroke-dasharray: 375.84952577718536px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 375.84952577718536px;
    }
}

@keyframes animate-svg-stroke-8-fid {
    0% {
        stroke-dashoffset: 375.84952577718536px;
        stroke-dasharray: 375.84952577718536px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 375.84952577718536px;
    }
}

@-webkit-keyframes animate-svg-stroke-9-fid {
    0% {
        stroke-dashoffset: 97.56314086914062px;
        stroke-dasharray: 97.56314086914062px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 97.56314086914062px;
    }
}

@keyframes animate-svg-stroke-9-fid {
    0% {
        stroke-dashoffset: 97.56314086914062px;
        stroke-dasharray: 97.56314086914062px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 97.56314086914062px;
    }
}



/* hari */


@-webkit-keyframes animate-svg-stroke-1-hari {
    0% {
        stroke-dashoffset: 2681.951904296875px;
        stroke-dasharray: 2681.951904296875px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 2681.951904296875px;
    }
}

@keyframes animate-svg-stroke-1-hari {
    0% {
        stroke-dashoffset: 2681.951904296875px;
        stroke-dasharray: 2681.951904296875px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 2681.951904296875px;
    }
}

.svg-hari-1 {
    -webkit-animation: animate-svg-stroke-1-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both;
    animation: animate-svg-stroke-1-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both;
}

@-webkit-keyframes animate-svg-stroke-2-hari {
    0% {
        stroke-dashoffset: 2217.000244140625px;
        stroke-dasharray: 2217.000244140625px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 2217.000244140625px;
    }
}

@keyframes animate-svg-stroke-2-hari {
    0% {
        stroke-dashoffset: 2217.000244140625px;
        stroke-dasharray: 2217.000244140625px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 2217.000244140625px;
    }
}

.svg-hari-2 {
    -webkit-animation: animate-svg-stroke-2-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both;
    animation: animate-svg-stroke-2-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both;
}

@-webkit-keyframes animate-svg-stroke-3-hari {
    0% {
        stroke-dashoffset: 120.26017761230469px;
        stroke-dasharray: 120.26017761230469px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 120.26017761230469px;
    }
}

@keyframes animate-svg-stroke-3-hari {
    0% {
        stroke-dashoffset: 120.26017761230469px;
        stroke-dasharray: 120.26017761230469px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 120.26017761230469px;
    }
}

.svg-hari-3 {
    -webkit-animation: animate-svg-stroke-3-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both;
    animation: animate-svg-stroke-3-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both;
}

@-webkit-keyframes animate-svg-stroke-4-hari {
    0% {
        stroke-dashoffset: 84.06863403320312px;
        stroke-dasharray: 84.06863403320312px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 84.06863403320312px;
    }
}

@keyframes animate-svg-stroke-4-hari {
    0% {
        stroke-dashoffset: 84.06863403320312px;
        stroke-dasharray: 84.06863403320312px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 84.06863403320312px;
    }
}

.svg-hari-4 {
    -webkit-animation: animate-svg-stroke-4-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both;
    animation: animate-svg-stroke-4-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both;
}

@-webkit-keyframes animate-svg-stroke-5-hari {
    0% {
        stroke-dashoffset: 217.31881713867188px;
        stroke-dasharray: 217.31881713867188px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 217.31881713867188px;
    }
}

@keyframes animate-svg-stroke-5-hari {
    0% {
        stroke-dashoffset: 217.31881713867188px;
        stroke-dasharray: 217.31881713867188px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 217.31881713867188px;
    }
}

.svg-hari-5 {
    -webkit-animation: animate-svg-stroke-5-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both;
    animation: animate-svg-stroke-5-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both;
}

@-webkit-keyframes animate-svg-stroke-6-hari {
    0% {
        stroke-dashoffset: 217.3189697265625px;
        stroke-dasharray: 217.3189697265625px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 217.3189697265625px;
    }
}

@keyframes animate-svg-stroke-6-hari {
    0% {
        stroke-dashoffset: 217.3189697265625px;
        stroke-dasharray: 217.3189697265625px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 217.3189697265625px;
    }
}

.svg-hari-6 {
    -webkit-animation: animate-svg-stroke-6-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both;
    animation: animate-svg-stroke-6-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both;
}

@-webkit-keyframes animate-svg-stroke-7-hari {
    0% {
        stroke-dashoffset: 127.21148681640625px;
        stroke-dasharray: 127.21148681640625px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 127.21148681640625px;
    }
}

@keyframes animate-svg-stroke-7-hari {
    0% {
        stroke-dashoffset: 127.21148681640625px;
        stroke-dasharray: 127.21148681640625px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 127.21148681640625px;
    }
}

.svg-hari-7 {
    -webkit-animation: animate-svg-stroke-7-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both;
    animation: animate-svg-stroke-7-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both;
}

@-webkit-keyframes animate-svg-stroke-8-hari {
    0% {
        stroke-dashoffset: 109.23307800292969px;
        stroke-dasharray: 109.23307800292969px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 109.23307800292969px;
    }
}

@keyframes animate-svg-stroke-8-hari {
    0% {
        stroke-dashoffset: 109.23307800292969px;
        stroke-dasharray: 109.23307800292969px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 109.23307800292969px;
    }
}

.svg-hari-8 {
    -webkit-animation: animate-svg-stroke-8-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s both;
    animation: animate-svg-stroke-8-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s both;
}

@-webkit-keyframes animate-svg-stroke-9-hari {
    0% {
        stroke-dashoffset: 109.23308563232422px;
        stroke-dasharray: 109.23308563232422px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 109.23308563232422px;
    }
}

@keyframes animate-svg-stroke-9-hari {
    0% {
        stroke-dashoffset: 109.23308563232422px;
        stroke-dasharray: 109.23308563232422px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 109.23308563232422px;
    }
}

.svg-hari-9 {
    -webkit-animation: animate-svg-stroke-9-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s both;
    animation: animate-svg-stroke-9-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s both;
}

@-webkit-keyframes animate-svg-stroke-10-hari {
    0% {
        stroke-dashoffset: 914.967529296875px;
        stroke-dasharray: 914.967529296875px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 914.967529296875px;
    }
}

@keyframes animate-svg-stroke-10-hari {
    0% {
        stroke-dashoffset: 914.967529296875px;
        stroke-dasharray: 914.967529296875px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 914.967529296875px;
    }
}

.svg-hari-10 {
    -webkit-animation: animate-svg-stroke-10-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s both;
    animation: animate-svg-stroke-10-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s both;
}

@-webkit-keyframes animate-svg-stroke-11-hari {
    0% {
        stroke-dashoffset: 255.41598510742188px;
        stroke-dasharray: 255.41598510742188px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 255.41598510742188px;
    }
}

@keyframes animate-svg-stroke-11-hari {
    0% {
        stroke-dashoffset: 255.41598510742188px;
        stroke-dasharray: 255.41598510742188px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 255.41598510742188px;
    }
}

.svg-hari-11 {
    -webkit-animation: animate-svg-stroke-11-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s both;
    animation: animate-svg-stroke-11-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s both;
}

@-webkit-keyframes animate-svg-stroke-12-hari {
    0% {
        stroke-dashoffset: 255.416015625px;
        stroke-dasharray: 255.416015625px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 255.416015625px;
    }
}

@keyframes animate-svg-stroke-12-hari {
    0% {
        stroke-dashoffset: 255.416015625px;
        stroke-dasharray: 255.416015625px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 255.416015625px;
    }
}

.svg-hari-12 {
    -webkit-animation: animate-svg-stroke-12-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s both;
    animation: animate-svg-stroke-12-hari 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s both;
}
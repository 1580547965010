.a {
    height: 100vh;
    display: flex;
    align-items: center;
}

.a-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.a-right {
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding: 50px;
}

/* .a-card.bg {
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: aqua;
} */

.a-card {
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position: absolute;
    overflow: hidden;
}

.a-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.a-title {
    /* font-weight: 400; */
    /* margin-left: 50px; */
    margin-block-end: 0;
    /* margin-bottom: 40px; */
    margin-left: 15px;
}

.a-details {
    flex-direction: column;
    justify-content: space-around;
}

/* .a-item {
    display: flex;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    position: relative;
    margin-bottom: 40px;
} */

.a-desc {
    margin-left: 15px;
}

.a-item-head {
    position: relative;
    font-weight: bold;
    font-size: 20px;
}

.a-item-item {
    position: relative;
    margin-left: 6px;
    font-size: 20px;
}

table {
    border-spacing: 0px;
    border-spacing: 15px 20px;
}

@media screen and (max-width:480px) {
    .a{
        flex-direction: column;
        text-align: center;
        margin-top: 50px;
    }

    .a-left {
        width: 100%;
    }

    .a-card {
        height: 30vh;
    }

    .a-right {
        padding: 20px;
    }
}
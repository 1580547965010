.s {
    height: 100vh;
    width: 100%;
    /* display: flex; */
    justify-content: center;
    align-items: center;
}

.s-img {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    height: 80vh;
    width: 90%;
    /* padding-left: 40px;
    padding-right: 40px; */
}

.s-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.s-title {
    font-size: 60px;
}
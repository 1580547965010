.w {
    display: flex;
    align-items: center;
}

.w-mob {
    display: none;
}

.w-app-head {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.w-app-title {
    font-size: 60px;
}

.w-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.w-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.w-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.w-card {
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    overflow: hidden;
}

.w-title {
    margin-block-end: 0;
}

.w-contain {
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.w-desc {
    font-size: larger;
}

@media screen and (max-width:480px) {
    .w{
        flex-direction: column;
        text-align: center;
    }

    .w-nor {
        display: none;
    }

    .w-mob {
        display: contents;
    }

    .w-card {
        height: 30vh;
    }
}

.green:hover {
    -webkit-animation: animate-svg-stroke-1 1.5s ease-in 0s both,
        animate-svg-fill-1 1.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.8s both;
    animation: animate-svg-stroke-1 1.5s ease-in 0s both,
        animate-svg-fill-1 1.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.8s both;
    -webkit-animation: animate-svg-stroke-2 1.5s ease-in 0.12s both,
        animate-svg-fill-2 1.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.8s both;
    animation: animate-svg-stroke-2 1.5s ease-in 0.12s both,
        animate-svg-fill-2 1.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.8s both;
}

.link:hover .svg-link-1 {
    -webkit-animation: animate-svg-stroke-1-link 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
        animate-svg-fill-1-link 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
    animation: animate-svg-stroke-1-link 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
        animate-svg-fill-1-link 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
}

.link:hover .svg-link-2 {
    -webkit-animation: animate-svg-stroke-2-link 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
        animate-svg-fill-2-link 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
    animation: animate-svg-stroke-2-link 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
        animate-svg-fill-2-link 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
}

.link:hover .svg-link-3 {
    -webkit-animation: animate-svg-stroke-3-link 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
        animate-svg-fill-3-link 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
    animation: animate-svg-stroke-3-link 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
        animate-svg-fill-3-link 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
}

.link:hover .svg-link-4 {
    -webkit-animation: animate-svg-stroke-4-link 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
        animate-svg-fill-4-link 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
    animation: animate-svg-stroke-4-link 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
        animate-svg-fill-4-link 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
}

.bot:hover .svg-bot-1 {
    -webkit-animation: animate-svg-stroke-1-bot 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
        animate-svg-fill-1-bot 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
    animation: animate-svg-stroke-1-bot 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
        animate-svg-fill-1-bot 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
}

.bot:hover .svg-bot-2 {
    -webkit-animation: animate-svg-stroke-2-bot 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
        animate-svg-fill-2-bot 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
    animation: animate-svg-stroke-2-bot 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
        animate-svg-fill-2-bot 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
}

.bot:hover .svg-bot-3 {
    -webkit-animation: animate-svg-stroke-3-bot 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
        animate-svg-fill-3-bot 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
    animation: animate-svg-stroke-3-bot 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
        animate-svg-fill-3-bot 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
}

.bot:hover .svg-bot-4 {
    -webkit-animation: animate-svg-stroke-4-bot 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
        animate-svg-fill-4-bot 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
    animation: animate-svg-stroke-4-bot 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
        animate-svg-fill-4-bot 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
}

.bot:hover .svg-bot-5 {
    -webkit-animation: animate-svg-stroke-5-bot 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
        animate-svg-fill-5-bot 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s both;
    animation: animate-svg-stroke-5-bot 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
        animate-svg-fill-5-bot 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s both;
}

.lms:hover .svg-lms-1 {
    -webkit-animation: animate-svg-stroke-1-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
        animate-svg-fill-1-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
    animation: animate-svg-stroke-1-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
        animate-svg-fill-1-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
}

.lms:hover .svg-lms-2 {
    -webkit-animation: animate-svg-stroke-2-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
        animate-svg-fill-2-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
    animation: animate-svg-stroke-2-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
        animate-svg-fill-2-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
}

.lms:hover .svg-lms-3 {
    -webkit-animation: animate-svg-stroke-3-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
        animate-svg-fill-3-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
    animation: animate-svg-stroke-3-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
        animate-svg-fill-3-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
}

.lms:hover .svg-lms-4 {
    -webkit-animation: animate-svg-stroke-4-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
        animate-svg-fill-4-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
    animation: animate-svg-stroke-4-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
        animate-svg-fill-4-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
}

.lms:hover .svg-lms-5 {
    -webkit-animation: animate-svg-stroke-5-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
        animate-svg-fill-5-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s both;
    animation: animate-svg-stroke-5-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
        animate-svg-fill-5-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s both;
}

.lms:hover .svg-lms-6 {
    -webkit-animation: animate-svg-stroke-6-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
        animate-svg-fill-6-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s both;
    animation: animate-svg-stroke-6-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
        animate-svg-fill-6-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s both;
}

.lms:hover .svg-lms-7 {
    -webkit-animation: animate-svg-stroke-7-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
        animate-svg-fill-7-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s both;
    animation: animate-svg-stroke-7-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
        animate-svg-fill-7-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s both;
}

.lms:hover .svg-lms-8 {
    -webkit-animation: animate-svg-stroke-8-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s both,
        animate-svg-fill-8-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
    animation: animate-svg-stroke-8-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s both,
        animate-svg-fill-8-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
}

.lms:hover .svg-lms-9 {
    -webkit-animation: animate-svg-stroke-9-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s both,
        animate-svg-fill-9-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both;
    animation: animate-svg-stroke-9-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s both,
        animate-svg-fill-9-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both;
}

.lms:hover .svg-lms-10 {
    -webkit-animation: animate-svg-stroke-10-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s both,
        animate-svg-fill-10-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s both;
    animation: animate-svg-stroke-10-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s both,
        animate-svg-fill-10-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s both;
}

.lms:hover .svg-lms-11 {
    -webkit-animation: animate-svg-stroke-11-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s both,
        animate-svg-fill-11-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s both;
    animation: animate-svg-stroke-11-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s both,
        animate-svg-fill-11-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s both;
}

.lms:hover .svg-lms-12 {
    -webkit-animation: animate-svg-stroke-12-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s both,
        animate-svg-fill-12-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s both;
    animation: animate-svg-stroke-12-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s both,
        animate-svg-fill-12-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s both;
}

.lms:hover .svg-lms-13 {
    -webkit-animation: animate-svg-stroke-13-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s both,
        animate-svg-fill-13-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s both;
    animation: animate-svg-stroke-13-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s both,
        animate-svg-fill-13-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s both;
}

.lms:hover .svg-lms-14 {
    -webkit-animation: animate-svg-stroke-14-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s both,
        animate-svg-fill-14-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s both;
    animation: animate-svg-stroke-14-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s both,
        animate-svg-fill-14-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s both;
}

.lms:hover .svg-lms-15 {
    -webkit-animation: animate-svg-stroke-15-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s both,
        animate-svg-fill-15-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s both;
    animation: animate-svg-stroke-15-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s both,
        animate-svg-fill-15-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s both;
}

.lms:hover .svg-lms-16 {
    -webkit-animation: animate-svg-stroke-16-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s both,
        animate-svg-fill-16-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s both;
    animation: animate-svg-stroke-16-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s both,
        animate-svg-fill-16-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s both;
}

.lms:hover .svg-lms-17 {
    -webkit-animation: animate-svg-stroke-17-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s both,
        animate-svg-fill-17-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s both;
    animation: animate-svg-stroke-17-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s both,
        animate-svg-fill-17-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s both;
}

.lms:hover .svg-lms-18 {
    -webkit-animation: animate-svg-stroke-18-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s both,
        animate-svg-fill-18-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s both;
    animation: animate-svg-stroke-18-lms 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s both,
        animate-svg-fill-18-lms 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s both;
}

.fid:hover .svg-fid-1 {
    -webkit-animation: animate-svg-stroke-1-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
        animate-svg-fill-1-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
    animation: animate-svg-stroke-1-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
        animate-svg-fill-1-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
}

.fid:hover .svg-fid-2 {
    -webkit-animation: animate-svg-stroke-2-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
        animate-svg-fill-2-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
    animation: animate-svg-stroke-2-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
        animate-svg-fill-2-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
}

.fid:hover .svg-fid-3 {
    -webkit-animation: animate-svg-stroke-3-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
        animate-svg-fill-3-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
    animation: animate-svg-stroke-3-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
        animate-svg-fill-3-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
}

.fid:hover .svg-fid-4 {
    -webkit-animation: animate-svg-stroke-4-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
        animate-svg-fill-4-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
    animation: animate-svg-stroke-4-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
        animate-svg-fill-4-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
}

.fid:hover .svg-fid-5 {
    -webkit-animation: animate-svg-stroke-5-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
        animate-svg-fill-5-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s both;
    animation: animate-svg-stroke-5-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
        animate-svg-fill-5-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s both;
}

.fid:hover .svg-fid-6 {
    -webkit-animation: animate-svg-stroke-6-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
        animate-svg-fill-6-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s both;
    animation: animate-svg-stroke-6-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
        animate-svg-fill-6-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s both;
}

.fid:hover .svg-fid-7 {
    -webkit-animation: animate-svg-stroke-7-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
        animate-svg-fill-7-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s both;
    animation: animate-svg-stroke-7-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
        animate-svg-fill-7-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s both;
}

.fid:hover .svg-fid-8 {
    -webkit-animation: animate-svg-stroke-8-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s both,
        animate-svg-fill-8-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
    animation: animate-svg-stroke-8-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s both,
        animate-svg-fill-8-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
}

.fid:hover .svg-fid-9 {
    -webkit-animation: animate-svg-stroke-9-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s both,
        animate-svg-fill-9-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both;
    animation: animate-svg-stroke-9-fid 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s both,
        animate-svg-fill-9-fid 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both;
}
.c {
    height: 100vh;
    display: flex;
    align-items: center;
}

.c-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.c-right {
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding: 50px;
}

.c-card {
    width: 60%;
    height: 60vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}

.c-title {
    position: relative;
    font-size: 50px;
}

.c-contain {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.c-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

input {
    width: 50%;
    height: 50px;
    border: none;
    border-bottom: 2px solid black;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
}

textarea {
    width: 100%;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
    border: 2px solid black;
    border-radius: 10px;
    margin-top: 40px;
    resize: vertical;
}

button {
    border: none;
    background-color: #59B256;
    padding: 20px;
    color: #ffffff;
    font-weight: 500;
    font-size: medium;
    cursor: pointer;
    border-radius: 10px;
    width: 100px;
}

.c-res {
    padding: 20px;
    color: #59B256;
}

@media screen and (max-width:480px) {
    .c {
        flex-direction: column;
    }

    .c-card {
        height: 30vh;
    }

    input {
        width: 100%;
    }
}
.i {
    display: flex;
    height: 100vh;
}

.i-404 {
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.p4-img {
    height: 10vh;
}

.p4-svg {
    width: 90%;
}

.i-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.i-right {
    flex: 1;
    position: relative;
}

.i-left-wrapper {
    padding: 50px;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.i-intro {
    font-size: 30px;
    font-weight: 300;
}

.i-name {
    font-size: 60px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.i-title-item {
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #59b256;
    display: flex;
    align-items: center;
}

.i-title {
    height: 50px;
    overflow: hidden;
}

.i-title-wrapper {
    height: 100%;
    animation: move 10s ease-in-out infinite alternate;
}

/* @keyframes move {
    25%{
        transform: translateY(-50px);
    }
    50%{
        transform: translateY(-100px);
    }
    75%{
        transform: translateY(-150px);
    }
    100%{
        transform: translateY(-200px);
    }
} */

@keyframes move {
    50%{
        transform: translateY(-50px);
    }
    100%{
        transform: translateY(-100px);
    }
}

.i-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
}

.i-desc {
    font-size: large;
    font-weight: bold;
    /* margin-block-end: 0; */
}

.i-above {
    margin-block-end: 0;
}

.i-below {
    margin-block-start: 0;
}

@media screen and (max-width: 480px) {
    .i {
        flex-direction: column;
    }

    .i-left-wrapper {
        padding: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
    }

    .i-desc {
        display: none;
    }
}
.f {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30vh;
    padding: 25px 50px;
    /* position: relative; */
}

.f-con {
    /* padding: 50px; */
    margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

.f-con:hover {
    transform: translateY(-2px);
}

.f-img {
    width: 40px;
    object-fit: contain;
}

@media screen and (max-width:480px) {
    .f {
        margin-top: 30vh;
        justify-content: start;
        width: 100%;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
body {
  margin: 0;
  font-family: 'Nunito';
  background-color: rgb(255, 255, 255);
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background: #59b256; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7bc278; 
}